import BigNumber from 'bignumber.js';
import { getContractAddress } from 'utilities';

import { VenusLens, UmiVault } from 'types/contracts';

export interface GetVaultInput {
  VaultContract: UmiVault;
  accountAddress: string;
}

export type GetVaultLevelOutput = {
  level: BigNumber;
};

const GetVaultLevel = async ({
                              VaultContract,
  accountAddress,
}: GetVaultInput): Promise<GetVaultLevelOutput> => {
  const res = await VaultContract.methods
    .Level(accountAddress)
    .call();

  return {
    level: new BigNumber(res),
  };
};

export default GetVaultLevel;
