/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { Paper, Typography } from '@mui/material';
import {
  useGetBalanceOf,
  useGetUserMarketInfo,
} from 'clients/api';
import { EllipseText, Icon, PrimaryButton } from 'components';
import { AuthContext } from 'context/AuthContext';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import {
  // generateBscScanUrl,
  getContractAddress,
  convertWeiToTokens,
  formatTokensToReadableValue,
} from 'utilities';
import { useTranslation } from 'translation';
import { useStyles } from './styles';
import MintRepayVai from '../MintRepayVai';

interface IHeaderProps {
  className?: string;
}

interface IHeaderContainerProps {
  dailyVenusWei: BigNumber;
  totalXvsDistributedWei: BigNumber;
}

export const HeaderUi: React.FC<IHeaderProps & IHeaderContainerProps> = ({
  className,
  dailyVenusWei,
  totalXvsDistributedWei,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { account: { address: accountAddress } = { address: undefined }, openAuthModal } =
      useContext(AuthContext);
  const copy = useCopyToClipboard(t('interactive.copy.xvsAddress'));

  // eslint-disable-next-line no-restricted-globals
  const address = `${location.protocol}//${location.host}/?address=${accountAddress}` || '';
  const copyAddress = () => copy(address);

  const connectedWallet = !!accountAddress;

  return (
    <Paper className={className} css={styles.headerRoot}>
      {!connectedWallet && (
          <PrimaryButton css={styles.actionButton} onClick={openAuthModal}>
            {t('connectWallet.connectButton')}
          </PrimaryButton>
      )}

      {connectedWallet && (
          <EllipseText css={styles.address} text={address}>
            <div css={styles.xvsIconContainer}>
              <Icon name="copy" size={styles.iconSize} />
            </div>

            <Typography
              className="ellipse-text"
              target="_blank"
              rel="noreferrer"
              variant="small2"
              component="a"
              css={[styles.whiteText, styles.addressText]}
            />

            <div css={styles.copyIconContainer}>
              <Icon name="copy" onClick={copyAddress} css={styles.copyIcon} size={styles.iconSizeXl} />
            </div>
          </EllipseText>
      )}

      <div>
        分享鏈接
      </div>
      {/* <div css={styles.slider}> */}
      {/*   <MintRepayVai css={styles.column} /> */}
      {/* </div> */}
    </Paper>
  );
};

const Header: React.FC<IHeaderProps> = ({ className }) => {
  const { account } = useContext(AuthContext);
  const {
    data: { dailyVenusWei, totalXvsDistributedWei },
  } = useGetUserMarketInfo({
    accountAddress: account?.address,
  });

  return (
    <HeaderUi
      className={className}
      dailyVenusWei={dailyVenusWei}
      totalXvsDistributedWei={totalXvsDistributedWei}
    />
  );
};

export default Header;
