import BigNumber from 'bignumber.js';

import { VBep20, VBnbToken, UmiToken, UmiVault } from 'types/contracts';

export interface GetOutroiInput {
    UmiVaultContract: UmiVault;
  accountAddress: string;

}

export type GetOutroiOutput = {
  inviter: string;
};

const getOutroi = async ({
                             UmiVaultContract,
                               accountAddress,
}: GetOutroiInput): Promise<GetOutroiOutput> => {
  const res = await UmiVaultContract.methods.outroi().call();

  return {
    inviter: res,
  };
};

export default getOutroi;
