import { VError } from 'errors';
import { VoterHistory } from 'types';
import { restService } from 'utilities';

import formatUserNumberResponse from './formatVoterHistoryResponse';
import { GetUserNumberResponse } from './types';

export interface GetUserNumberOutput {
  usercount: number;
  syndicatecount: number;
}

const getUserNumber = async (): Promise<GetUserNumberOutput> => {
  const response = await restService<GetUserNumberResponse>({
    endpoint: '/',
    method: 'GET',
    params: {},
  });


  const payload = response.data;

  console.log(payload);
  // @todo Add specific api error handling
  if ('result' in response && response.result === 'error') {
    throw new VError({
      type: 'unexpected',
      code: 'somethingWentWrong',
      data: { message: response.message },
    });
  }

  if (!payload) {
    throw new VError({ type: 'unexpected', code: 'somethingWentWrongRetrievingVoterHistory' });
  }

  return formatUserNumberResponse(payload);
};

export default getUserNumber;
