import BigNumber from 'bignumber.js';

import { VBep20, VBnbToken, UmiToken, UmiVault } from 'types/contracts';

export interface GetSyndicateRateL1Input {
    UmiVaultContract: UmiVault;
  accountAddress: string;

}

export type GetSyndicateRateL1Output = {
  req: string;
};

const getSyndicateRateL1 = async ({
                          UmiVaultContract,
                               accountAddress,
}: GetSyndicateRateL1Input): Promise<GetSyndicateRateL1Output> => {
  const res = await UmiVaultContract.methods.syndicateRateL1().call();

  return {
      req: res,
  };
};

export default getSyndicateRateL1;
