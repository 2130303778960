enum FunctionKey {
  // Queries
  GET_BLOCK_NUMBER = 'GET_BLOCK_NUMBER',
  GET_VAI_CALCULATE_REPAY_AMOUNT = 'GET_VAI_CALCULATE_REPAY_AMOUNT',
  GET_VAI_TREASURY_PERCENTAGE = 'GET_VAI_TREASURY_PERCENTAGE',
  GET_MARKETS = 'GET_MARKETS',
  GET_MARKET_HISTORY = 'GET_MARKET_HISTORY',
  GET_ASSETS_IN_ACCOUNT = 'GET_ASSETS_IN_ACCOUNT',
  GET_VENUS_ACCRUED = 'GET_VENUS_ACCRUED',
  GET_VENUS_VAI_STATE = 'GET_VENUS_VAI_STATE',
  GET_MINTED_VAI = 'GET_MINTED_VAI',
  GET_MINTED_USERIFNO = 'GET_MINTED_USERIFNO',

  GET_MINTED_USERINFO = 'GET_MINTED_USERINFO',

  GET_MINTED_UMI = 'GET_MINTED_UMI',

  GET_UMI_UPLINE = 'GET_UMI_UPLINE',
  GET_XVS_REWARD = 'GET_XVS_REWARD',
  GET_UMI_UMIPRICE = 'GET_UMI_UMIPRICE',
  GET_XVS_GETLEVEL = 'GET_XVS_GETLEVEL',
  GET_TOKEN_ALLOWANCE = 'GET_TOKEN_ALLOWANCE',
  GET_BALANCE_OF = 'GET_BALANCE_OF',
  GET_TOKEN_BALANCES = 'GET_TOKEN_BALANCES',
  GET_MINTABLE_VAI = 'GET_MINTABLE_VAI',
  GET_VRT_CONVERSION_END_TIME = 'GET_VRT_CONVERSION_END_TIME',
  GET_VRT_CONVERSION_RATIO = 'GET_VRT_CONVERSION_RATIO',
  GET_XVS_WITHDRAWABLE_AMOUNT = 'GET_XVS_WITHDRAWABLE_AMOUNT',
  GET_VENUS_VAI_VAULT_DAILY_RATE = 'GET_VENUS_VAI_VAULT_DAILY_RATE',
  GET_VENUS_VAI_VAULT_RATE = 'GET_VENUS_VAI_VAULT_RATE',
  GET_V_TOKEN_CASH = 'GET_V_TOKEN_CASH',
  GET_V_TOKEN_INVITER = 'GET_V_TOKEN_INVITER',
  GET_V_TOKEN_BORROW_BALANCE = 'GET_V_TOKEN_BORROW_BALANCE',
  GET_V_TOKEN_BALANCE = 'GET_V_TOKEN_BALANCE',
  GET_V_TOKEN_BALANCES_ALL = 'GET_V_TOKEN_BALANCES_ALL',
  GET_V_TOKEN_DAILY_XVS = 'GET_V_TOKEN_DAILY_XVS',
  GET_V_TOKEN_INTEREST_RATE_MODEL = 'GET_V_TOKEN_INTEREST_RATE_MODEL',
  GET_V_TOKEN_APY_SIMULATIONS = 'GET_V_TOKEN_APY_SIMULATIONS',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  GET_XVS_VAULT_REWARD_PER_BLOCK = 'GET_XVS_VAULT_REWARD_PER_BLOCK',
  GET_XVS_VAULT_TOTAL_ALLOCATION_POINTS = 'GET_XVS_VAULT_TOTAL_ALLOCATION_POINTS',
  GET_XVS_VAULT_POOL_INFOS = 'GET_XVS_VAULT_POOL_INFOS',
  GET_XVS_VAULT_PENDING_REWARD = 'GET_XVS_VAULT_PENDING_REWARD',
  GET_XVS_VAULT_USER_INFO = 'GET_XVS_VAULT_USER_INFO',
  GET_XVS_VAULT_POOLS_COUNT = 'GET_XVS_VAULT_POOLS_COUNT',
  GET_XVS_VAULT_WITHDRAWAL_REQUESTS = 'GET_XVS_VAULT_WITHDRAWAL_REQUESTS',
  GET_CURRENT_VOTES = 'GET_CURRENT_VOTES',
  GET_VOTERS = 'GET_VOTERS',
  GET_PENDING_XVS = 'GET_PENDING_XVS',
  GET_PROPOSALS = 'GET_PROPOSALS',
  GET_PROPOSAL = 'GET_PROPOSAL',
  GET_VOTE_RECEIPT = 'GET_VOTE_RECEIPT',
  GET_VAI_VAULT_USER_INFO = 'GET_VAI_VAULT_USER_INFO',
  GET_VOTE_DELEGATE_ADDRESS = 'GET_VOTE_DELEGATE_ADDRESS',
  GET_PROPOSAL_STATE = 'GET_PROPOSAL_STATE',
  GET_LATEST_PROPOSAL_ID_BY_PROPOSER = 'GET_LATEST_PROPOSAL_ID_BY_PROPOSER',
  GET_VAI_VAULT_PENDING_XVS = 'GET_VAI_VAULT_PENDING_XVS',
  GET_VRT_VAULT_INTEREST_RATE_PER_BLOCK = 'GET_VRT_VAULT_INTEREST_RATE_PER_BLOCK',
  GET_VRT_VAULT_USER_INFO = 'GET_VRT_VAULT_USER_INFO',
  GET_VRT_VAULT_ACCRUED_INTEREST = 'GET_VRT_VAULT_ACCRUED_INTEREST',
  GET_VOTER_ACCOUNTS = 'GET_VOTER_ACCOUNTS',
  GET_VOTER_DETAILS = 'GET_VOTER_DETAILS',
  GET_VOTER_HISTORY = 'GET_VOTER_HISTORY',
  GET_USER_NUMBER = 'GET_USER_NUMBER',
  GET_PROPOSAL_THRESHOLD = 'GET_PROPOSAL_THRESHOLD',
  GET_PROPOSAL_ETA = 'GET_PROPOSAL_ETA',
  GET_PANCAKE_SWAP_PAIRS = 'GET_PANCAKE_SWAP_PAIRS',

  // Mutations
  MINT_VAI = 'MINT_VAI',

  MINT_UMI = 'MINT_UMI',

  ENTER_MARKETS = 'ENTER_MARKETS',
  EXIT_MARKET = 'EXIT_MARKET',
  REPAY_VAI = 'REPAY_VAI',
  SetOrganization = 'SetOrganization',
  SET_OUT_ROI = 'SET_OUT_ROI',
  SetRoi = 'SetRoi',
  SET_SYNDICATERATEL1 = 'SET_SYNDICATERATEL1',
  SET_SYNDICATERATEL2 = 'SET_SYNDICATERATEL2',
  SetSyndicate = 'SetSyndicate',
  AIRDROP = 'AIRDROP',
  APPROVE_TOKEN = 'APPROVE_TOKEN',
  APPROVE_VRT = 'APPROVE_VRT',
  CONVERT_VRT = 'CONVERT_VRT',
  SUPPLY = 'SUPPLY',
  SUPPLY_BNB = 'SUPPLY_BNB',
  REDEEM = 'REDEEM',
  REDEEM_UNDERLYING = 'REDEEM_UNDERLYING',
  CLAIM_XVS_REWARD = 'CLAIM_XVS_REWARD',
  REPAY_NON_BNB_V_TOKEN = 'REPAY_NON_BNB_V_TOKEN',
  REPAY_BNB = 'REPAY_BNB',
  BORROW_V_TOKEN = 'BORROW_V_TOKEN',
  WITHDRAW_XVS = 'WITHDRAW_XVS',
  CREATE_PROPOSAL = 'CREATE_PROPOSAL',
  EXECUTE_PROPOSAL = 'EXECUTE_PROPOSAL',
  QUEUE_PROPOSAL = 'QUEUE_PROPOSAL',
  CANCEL_PROPOSAL = 'CANCEL_PROPOSAL',
  SET_VOTE_DELEGATE = 'SET_VOTE_DELEGATE',
  CLAIM_VAI_VAULT_REWARD = 'CLAIM_VAI_VAULT_REWARD',
  CLAIM_VRT_VAULT_REWARD = 'CLAIM_VRT_VAULT_REWARD',
  CLAIM_XVS_VAULT_REWARD = 'CLAIM_XVS_VAULT_REWARD',
  CAST_VOTE = 'CAST_VOTE',
  CAST_VOTE_WITH_REASON = 'CAST_VOTE_WITH_REASON',
  STAKE_IN_XVS_VAULT = 'STAKE_IN_XVS_VAULT',
  STAKE_IN_VAI_VAULT = 'STAKE_IN_VAI_VAULT',
  STAKE_IN_VRT_VAULT = 'STAKE_IN_VRT_VAULT',
  WITHDRAW_FROM_VAI_VAULT = 'WITHDRAW_FROM_VAI_VAULT',
  WITHDRAW_FROM_VRT_VAULT = 'WITHDRAW_FROM_VRT_VAULT',
  REQUEST_WITHDRAWAL_FROM_XVS_VAULT = 'REQUEST_WITHDRAWAL_FROM_XVS_VAULT',
  EXECUTE_WITHDRAWAL_FROM_XVS_VAULT = 'EXECUTE_WITHDRAWAL_FROM_XVS_VAULT',
  SWAP_TOKENS = 'SWAP_TOKENS',

  SET_INVITER = 'SET_INVITER',

  GET_INVITER = 'GET_INVITER',
  GET_ROI = 'GET_ROI',
  GET_SYNDICATERATEL1 = 'GET_SYNDICATERATEL1',
  GET_SYNDICATERATEL2 = 'GET_SYNDICATERATEL2',
  GET_OUTROI = 'GET_OUTROI',
  GET_Organization = 'GET_Organization',
  GET_REWARDS_POOL = 'GET_REWARDS_POOL',
  GET_STAKE_POOL = 'GET_STAKE_POOL',
  GET_CUMI_POOL = 'GET_CUMI_POOL',
  GET_SNDICATE_GLOABL_AMOUNT = 'GET_SNDICATE_GLOABL_AMOUNT',

}

export default FunctionKey;
