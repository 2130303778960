import { QueryObserverOptions, useQuery } from 'react-query';

import { useUmiVaultContract } from 'clients/contracts/hooks';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

import getUpline, { GetUplineInput, GetUplineOutput } from '.';

type Options = QueryObserverOptions<
    GetUplineOutput,
  Error,
    GetUplineOutput,
    GetUplineOutput,
  [FunctionKey.GET_UMI_UPLINE, string]
>;

const useGetUpline = (
  { accountAddress }: Omit<GetUplineInput, 'VaultContract'>,
  options?: Options,
) => {
  const VaultContract = useUmiVaultContract();

  return useQuery(
    [FunctionKey.GET_UMI_UPLINE, accountAddress],
    () =>
      getUpline({
          VaultContract,
        accountAddress,
      }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
    },
  );
};

export default useGetUpline;
