/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import {
  ConnectWallet,
  EnableToken,
  FormikSubmitButton,
  FormikTokenTextField,
  Icon,
  TokenIcon,
  TokenTextField,
  toast,
} from 'components';
import noop from 'noop-ts';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'translation';
import {
  convertTokensToWei,
  convertWeiToTokens,
  formatTokensToReadableValue,
  getContractAddress,
} from 'utilities';
import type { TransactionReceipt } from 'web3-core/types';

import { TOKENS } from 'constants/tokens';
import { AmountForm, ErrorCode } from 'containers/AmountForms';
import { DisableLunaUstWarningContext } from 'context/DisableLunaUstWarning';
import { VError } from 'errors/VError';
import useConvertWeiToReadableTokenString from 'hooks/useConvertWeiToReadableTokenString';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';

import { useStyles } from '../styles';
import TEST_IDS from './testIds';
import { GetMintedUmiOutput, useGetUmiPrice } from '../../../clients/api';
import { AuthContext } from '../../../context/AuthContext';

export interface ConvertProps {
  xvsToVrtConversionRatio: BigNumber | undefined;
  userVrtBalanceWei: BigNumber | undefined;
  convertVrtLoading: boolean;
  convertVrt: (amount: string) => Promise<TransactionReceipt>;
  userMinte?: GetMintedUmiOutput;

  pumice?: string;
}

const vrtConverterProxyContractAddress = getContractAddress('umiVault');

const Convert: React.FC<ConvertProps> = ({
  xvsToVrtConversionRatio,
  userVrtBalanceWei,
  convertVrtLoading,
  convertVrt,
                                           userMinte,
                                           pumice = '',
}) => {
  const styles = useStyles();
  const { account } = useContext(AuthContext);
  const accountAddress = account?.address;
  const { t, Trans } = useTranslation();
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();
  const userCumiBalanceWei = userMinte ? userMinte.balance : 0;

  const { hasLunaOrUstCollateralEnabled, openLunaUstWarningModal } = useContext(
    DisableLunaUstWarningContext,
  );

  const readableXvsAvailable = useConvertWeiToReadableTokenString({
    valueWei: new BigNumber(userCumiBalanceWei),
    token: TOKENS.hktlp,
  });

  const readableUserVrtBalance = useMemo(() => {
    const userVrtBalanceTokens =
      convertWeiToTokens({
        valueWei: new BigNumber(userCumiBalanceWei),
        token: TOKENS.hktlp,
      });

    return formatTokensToReadableValue({
      value: new BigNumber(userVrtBalanceTokens),
      token: TOKENS.hktlp,
    });
  }, [userCumiBalanceWei]);

  useConvertWeiToReadableTokenString({
    valueWei: userVrtBalanceWei,
    token: TOKENS.hktlp,
  });
  const calculateXvsFromVrt = useCallback(
      (value: BigNumber) =>
      !value.isNaN() && !value.isZero() && userMinte
        ? new BigNumber(value).div(new BigNumber(pumice).dividedBy(new BigNumber(10).pow(18)))
        : undefined,
    [xvsToVrtConversionRatio],
  );

  const onSubmit = async (vrtAmount: string) => {
    // Block action is user has LUNA or UST enabled as collateral
    if (hasLunaOrUstCollateralEnabled) {
      openLunaUstWarningModal();
      return;
    }

    try {
      const vrtAmountWei = convertTokensToWei({
        value: new BigNumber(vrtAmount),
        token: TOKENS.hktlp,
      });
      const transactionReceipt = await convertVrt(vrtAmountWei.toFixed());
      // Display successful transaction modal
      if (!xvsToVrtConversionRatio) {
        // This should never happen because the form is not rendered without successfully fetching this
        throw new VError({
          type: 'unexpected',
          code: 'internalErrorXvsToVrtConversionRatioUndefined',
        });
      }

      const xvsAmountWei = calculateXvsFromVrt(vrtAmountWei);

      openSuccessfulTransactionModal({
          title: t('convertVrt.successfulConvertTransactionModal.title'),
          transactionHash: transactionReceipt.transactionHash,
          content: (
              <div css={styles.successModalConversionAmounts}>
                  <TokenIcon token={TOKENS.hktlp} css={styles.successModalToken} />

                  <Typography variant="small2" css={[styles.fontWeight600, styles.successMessage]}>
                      {convertWeiToTokens({
                          valueWei: vrtAmountWei,
                          token: TOKENS.hktlp,
                          returnInReadableFormat: true,
                      })}
                  </Typography>
                  <Icon name="arrowShaft" css={styles.successModalArrow} />

                  <TokenIcon token={TOKENS.hktlp} css={styles.successModalToken} />

                  <Typography variant="small2" css={[styles.fontWeight600, styles.successMessage]}>
                      {xvsAmountWei &&
                          convertWeiToTokens({
                              valueWei: xvsAmountWei,
                              token: TOKENS.hkt,
                              returnInReadableFormat: true,
                          })}
                  </Typography>
              </div>
          ),
      });
    } catch (err) {
      toast.error({ message: (err as Error).message });
    }
  };

  const userVrtBalance =
      userMinte &&
    convertWeiToTokens({ valueWei: new BigNumber(userMinte.balance), token: TOKENS.hktlp }).toFixed();

  return (
    <div css={styles.root}>
      <ConnectWallet message={t('convertVrt.connectWalletToConvertVrtToXvs')}>
        <EnableToken
          title={t('convertVrt.enableVrt')}
          token={TOKENS.hktlp}
          spenderAddress={vrtConverterProxyContractAddress}
        >
          <section css={styles.title}>
            <Typography variant="h3">{readableXvsAvailable}</Typography>
            <Typography variant="small2">{t('convertVrt.xvsAVailable')}</Typography>
          </section>

          <AmountForm onSubmit={onSubmit} maxAmount={userVrtBalance} css={styles.form}>
            {({ values }) => {
              const xvsValue = calculateXvsFromVrt(new BigNumber(values.amount))
                ?.dp(TOKENS.hktlp.decimals)
                .toFixed();
              return (
                <>
                  <div css={styles.inputSection}>
                    <Typography variant="small2" css={[styles.inputLabel, styles.fontWeight600]}>
                      {t('convertVrt.convertVrt')}
                    </Typography>
                    <FormikTokenTextField
                      token={TOKENS.hktlp}
                      name="amount"
                      css={styles.input}
                      description={
                        <Trans
                          i18nKey="convertVrt.balance"
                          components={{
                            White: <span css={styles.whiteLabel} />,
                          }}
                          values={{
                            amount: readableUserVrtBalance,
                          }}
                        />
                      }
                      rightMaxButton={
                        userVrtBalance
                          ? {
                              label: t('convertVrt.max'),
                              valueOnClick: userVrtBalance,
                            }
                          : undefined
                      }
                      displayableErrorCodes={[ErrorCode.HIGHER_THAN_MAX]}
                      data-testid={TEST_IDS.vrtTokenTextField}
                    />
                  </div>
                  <div css={styles.inputSection}>
                    <Typography variant="small2" css={[styles.inputLabel, styles.fontWeight600]}>
                      {t('convertVrt.youWillReceive')}
                    </Typography>
                    <TokenTextField
                      token={TOKENS.hkt}
                      name="hkt"
                      css={styles.input}
                      description={t('convertVrt.vrtEqualsXvs', {
                        xvsToVrtConversionRatio: new BigNumber(1).div(new BigNumber(pumice).dividedBy(new BigNumber(10).pow(18))),
                      })}
                      disabled
                      value={xvsValue || ''}
                      onChange={noop}
                      data-testid={TEST_IDS.xvsTokenTextField}
                    />
                  </div>
                  <FormikSubmitButton
                    css={styles.submitButton}
                    fullWidth
                    loading={convertVrtLoading}
                    enabledLabel={t('convertVrt.convertVrtToXvs')}
                  />
                </>
              );
            }}
          </AmountForm>
        </EnableToken>
      </ConnectWallet>
    </div>
  );
};

export default Convert;
