import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  const gap = theme.spacing(8);

  return {
    header: css`
      margin-bottom: ${theme.spacing(6)};
      padding: ${theme.spacing(8)};
      ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(6)};
      }
      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(4)};
      }
    `,
    row: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: ${gap};

      ${theme.breakpoints.down('xl')} {
        flex-direction: column;
        margin-bottom: 0;
      }
    `,
    column: css`
      width: calc(50% - ${gap} / 2);

      ${theme.breakpoints.down('xl')} {
        width: 100%;
        margin-bottom: ${gap};
      }
    `,
  };
};
