import type { TransactionReceipt } from 'web3-core';
import { UmiToken, Bep20, VaiToken, VrtToken, XvsToken } from 'types/contracts';

export interface ISetInviterInput {
    UmiTokenContract: UmiToken;
    accountAddress: string;
    delegateAddress: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type setInviterOutput = TransactionReceipt;

const setInviter = async ({ UmiTokenContract,
                                   accountAddress,
                                   delegateAddress,
                               }: ISetInviterInput) =>
    UmiTokenContract.methods.setInviter(delegateAddress).send({ from: accountAddress });

export default setInviter;
