import { MutationObserverOptions, QueryObserverOptions, useMutation, useQuery } from 'react-query';

import getSndicateGloablAmount, { GetSndicateGloablAmountOutput, GetSndicateGloablAmountInput } from 'clients/api/queries/getSyndicateGloblAmount';
import { useUmiVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import queryClient from 'clients/api/queryClient';


type Options = QueryObserverOptions<
    GetSndicateGloablAmountOutput,
    Error,
    GetSndicateGloablAmountOutput,
    GetSndicateGloablAmountOutput,
    [FunctionKey.GET_SNDICATE_GLOABL_AMOUNT, string]
>;

const useGetSndicateGloblAmount = (
    { accountAddress }: Omit<GetSndicateGloablAmountInput, 'UmiVaultContract'>,
    options?: Options,
) => {
  const UmiVaultContract = useUmiVaultContract();
    return useQuery(
        [FunctionKey.GET_SNDICATE_GLOABL_AMOUNT, accountAddress],
        () =>
            getSndicateGloablAmount({
                UmiVaultContract,
                accountAddress,
            }),
        options,
    );
};

export default useGetSndicateGloblAmount;
