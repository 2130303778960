import BigNumber from 'bignumber.js';
import { formatPercentage } from 'utilities';

import PLACEHOLDER_KEY from 'constants/placeholderKey';

const formatToReadablePercentage = (value: number | string | BigNumber | undefined) => {
  if (value === undefined) {
    return PLACEHOLDER_KEY;
  }

  return `${formatPercentage(new BigNumber(value).dividedBy(new BigNumber(10).pow(18)))}%`;
};

export default formatToReadablePercentage;
