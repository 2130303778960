import { QueryObserverOptions, useQuery } from 'react-query';

import getUserNumber, {
    GetUserNumberOutput,
} from 'clients/api/queries/getUserNumber/getUserNumber';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
    GetUserNumberOutput,
  Error,
    GetUserNumberOutput,
    GetUserNumberOutput,
  [FunctionKey.GET_USER_NUMBER]
>;

const useGetUserNumber = (options?: Options) =>
  // This endpoint is paginated so we keep the previous responses by default to create a more seamless paginating experience
  useQuery([FunctionKey.GET_USER_NUMBER], () => getUserNumber(), {
    keepPreviousData: true,
    refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
    ...options,
  });

export default useGetUserNumber;
