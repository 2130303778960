import { MutationObserverOptions, useMutation } from 'react-query';
import { queryClient, setSyndicate, IsetSyndicateInput, IsetSyndicateOutput } from 'clients/api';
import { useVaiControllerContract, useUmiTokenContract, useUmiVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
    IsetSyndicateOutput,
  Error,
  Omit<IsetSyndicateInput, 'umiControllerContract'>
>;

const useSetSyndicate = (options?: Options) => {
  const umiControllerContract = useUmiVaultContract();

  return useMutation(
    FunctionKey.SetSyndicate,
    (params: Omit<IsetSyndicateInput, 'umiControllerContract'>) =>
        setSyndicate({
          umiControllerContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        // Invalidate queries related to fetching the user minted VAI amount
        queryClient.invalidateQueries(FunctionKey.GET_MINTED_USERINFO);
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useSetSyndicate;
