/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import {
  BorrowBalanceAccountHealth,
  Delimiter,
  FormikSubmitButton,
  FormikTokenTextField,
  FormikUpdateTokenTextField,
  LabeledInlineContent,
  LabeledInlineContentProps,
  ValueUpdate,
  toast, ButtonGroup,
} from 'components';
import { VError, formatVErrorToReadableString } from 'errors';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'translation';
import { Asset, Vault, Token } from 'types';
import {
  calculateCollateralValue,
  calculateDailyEarningsCents,
  calculateYearlyEarningsForAssets,
  convertTokensToWei,
  formatTokensToReadableValue,
  getBigNumber,
} from 'utilities';
import { AuthContext } from 'context/AuthContext';

import { SAFE_BORROW_LIMIT_PERCENTAGE } from 'constants/safeBorrowLimitPercentage';
import { AmountForm, AmountFormProps, ErrorCode } from 'containers/AmountForm';
import useDailyXvsDistributionInterests from 'hooks/useDailyXvsDistributionInterests';
import { MAINNET_PANCAKE_SWAP_TOKENS, PANCAKE_SWAP_TOKENS, TESTNET_PANCAKE_SWAP_TOKENS, TOKENS } from 'constants/tokens';

import {
  useGetMintableVai,
  useGetVaiTreasuryPercentage,
  useMintVai,
  useMintUmi,
  useGetBalanceOf,
  useGetInviter, useGetXvsReward, useGetUmiPrice, useGetMintedUmi,
} from 'clients/api';
import { useStyles } from '../styles';
import useConvertWeiToReadableTokenString from '../../../../hooks/useConvertWeiToReadableTokenString';
import { FormValues } from '../../../Swap/types';
import config from '../../../../config';


interface SupplyWithdrawFormUiProps {
  asset: Vault;
  assets: Vault[];
  type: 'supply' | 'withdraw';
  tokenInfo: LabeledInlineContentProps[];
  maxInput: BigNumber;
  userTotalBorrowBalanceCents: BigNumber;
  userTotalBorrowLimitCents: BigNumber;
  inputLabel: string;
  enabledButtonKey: string;
  disabledButtonKey: string;
  calculateNewBalance: (initial: BigNumber, amount: BigNumber) => BigNumber;
  setFormValues: (setter: (currentFormValues: FormValues) => FormValues) => void;
  formValues: FormValues;

  isTransactionLoading: boolean;
  isXvsEnabled: boolean;
  amountValue: string;
  days: number;
  setDays: (setter: (arg:number) => number) => void;
}

export const SupplyWithdrawContent: React.FC<SupplyWithdrawFormUiProps> = ({
  asset,
  type,
  tokenInfo,
  userTotalBorrowBalanceCents,
  userTotalBorrowLimitCents,
  assets,
  maxInput,
  inputLabel,
  enabledButtonKey,
  disabledButtonKey,
  calculateNewBalance,
  isTransactionLoading,
  isXvsEnabled,
  amountValue,
                                                                             formValues,
                                                                             setFormValues,
                                                                             days,
                                                                             setDays,
}) => {
  const styles = useStyles();
  const { t, Trans } = useTranslation();
    const { account } = useContext(AuthContext);
    const accountAddress = account?.address;

  const amount = new BigNumber(amountValue || 0);
  const validAmount = amount && !amount.isZero() && !amount.isNaN();

  const { data: userMintedUmiData, isLoading: isGetUserMintedUmiLoading } = useGetMintedUmi(
      {
        accountAddress: account?.address || '',
      },
      {
        enabled: !!account?.address,
      },
  );
  const hypotheticalTokenSupplyBalance = amountValue
    ? calculateNewBalance(asset.supplyApy, amount)
    : undefined;

  // TODO: handle loading state
  const { dailyXvsDistributionInterestsCents } = useDailyXvsDistributionInterests();

  const hypotheticalBorrowLimitCents = useMemo(() => {
    const tokenPrice = getBigNumber(asset?.walletBalance);
    let updateBorrowLimitCents;

    if (tokenPrice && validAmount) {
      const amountInCents = calculateCollateralValue({
        amountWei: convertTokensToWei({ value: amount, token: asset.token }),
        token: asset.token,
        tokenPriceTokens: asset.walletBalance,
        collateralFactor: asset.collateralFactor,
      }).times(100);

      const temp = calculateNewBalance(userTotalBorrowLimitCents, amountInCents);
      updateBorrowLimitCents = BigNumber.maximum(temp, 0);
    }

    return updateBorrowLimitCents;
  }, [amount, asset.token, userTotalBorrowBalanceCents, userTotalBorrowLimitCents]);

  const [dailyEarningsCents, hypotheticalDailyEarningCents] = useMemo(() => {
    let hypotheticalDailyEarningCentsValue;
    const hypotheticalAssets = [...assets];
    const yearlyEarningsCents =
      dailyXvsDistributionInterestsCents &&
      calculateYearlyEarningsForAssets({
        assets,
        isXvsEnabled,
        dailyXvsDistributionInterestsCents,
      });
    const dailyEarningsCentsValue =
      yearlyEarningsCents && calculateDailyEarningsCents(yearlyEarningsCents);

    // Modify asset with hypotheticalBalance
    if (validAmount) {
      const hypotheticalAsset = {
        ...asset,
        supplyBalance: calculateNewBalance(asset.walletBalance, amount),
      };
      const currentIndex = assets.findIndex(a => a.token.address === asset.token.address);
      hypotheticalAssets.splice(currentIndex, 1, hypotheticalAsset);
      const hypotheticalYearlyEarningsCents =
        dailyXvsDistributionInterestsCents &&
        calculateYearlyEarningsForAssets({
          assets: hypotheticalAssets,
          isXvsEnabled,
          dailyXvsDistributionInterestsCents,
        });
      hypotheticalDailyEarningCentsValue =
        hypotheticalYearlyEarningsCents &&
        calculateDailyEarningsCents(hypotheticalYearlyEarningsCents);
    }
    return [dailyEarningsCentsValue, hypotheticalDailyEarningCentsValue];
  }, [amount, asset.token.address, isXvsEnabled, JSON.stringify(assets)]);

  // Prevent users from supplying LUNA tokens. This is a temporary hotfix
  // following the crash of the LUNA token
  const isSupplyingLuna = type === 'supply' && asset.token.id === 'luna';

    const { data: stakedTokenBalanceData } = useGetBalanceOf(
        { accountAddress: accountAddress || '', token: asset.stakedTokenId },
        { enabled: !!accountAddress },
    );

    const { data: rewardTokenBalanceData } = useGetBalanceOf(
        { accountAddress: accountAddress || '', token: asset.rewardTokenId },
        { enabled: !!accountAddress },
    );

  const { data: Price } = useGetUmiPrice(
      { accountAddress: accountAddress || '' },
      { enabled: !!accountAddress },
  );
  const bnbPrice = Price ? new BigNumber(Price.getBnbPrice).dividedBy(new BigNumber(10).pow(18)) : new BigNumber(0);
  const umiPrice = Price ? new BigNumber(Price.getUmiPrice).dividedBy(new BigNumber(10).pow(18)) : new BigNumber(0);
  const smcPrice = Price ? new BigNumber(Price.getSmcPrice).dividedBy(new BigNumber(10).pow(18)) : new BigNumber(0);
  const hktPrice = Price ? new BigNumber(Price.getPoolPrice).dividedBy(new BigNumber(10).pow(18)) : new BigNumber(0);

  const hypotheticalBnbToken = (value: any) => parseFloat(bnbPrice.toFixed()) / parseFloat(umiPrice.toFixed()) * value;
  const hypotheticalUmiToken = (value: any) => parseFloat(umiPrice.toFixed()) / parseFloat(bnbPrice.toFixed()) * value;
  const hypotheticalUsdtToken = (value: any) => parseFloat(umiPrice.toFixed()) * value;
  const hypotheticalUsdtUmiToken = (value: any) => value / parseFloat(umiPrice.toFixed());
  const hypotheticalSmcBnbToken = (value: any) => parseFloat(bnbPrice.toFixed()) / parseFloat(smcPrice.toFixed()) * value;
  const hypotheticalSmcToken = (value: any) => parseFloat(smcPrice.toFixed()) / parseFloat(bnbPrice.toFixed()) * value;
  const hypotheticalSmcUsdtToken = (value: any) => parseFloat(smcPrice.toFixed()) * value;
  const hypotheticalUsdtSmcToken = (value: any) => value / parseFloat(smcPrice.toFixed());
  const hypotheticalHktUsdtToken = (value: any) => parseFloat(hktPrice.toFixed()) * value;
  const hypotheticalUsdtHktToken = (value: any) => value / parseFloat(hktPrice.toFixed());
  const calcaAmount = (value:any, tokenA: Token, tokenB: Token) => {
    console.log(tokenA.id);
    console.log(tokenB.id);
    console.log(value);
    console.log(smcPrice.toFixed());
     if (tokenA.id === 'umi') {
       if (tokenB.id === 'bnb') {
         return hypotheticalUmiToken(value);
       }
       if (tokenB.id === 'usdt'){
         return hypotheticalUsdtToken(value);
       }
     }
    if (tokenA.id === 'hkt') {
      if (tokenB.id === 'usdt') {
        return hypotheticalHktUsdtToken(value);
      }
    }
     if (tokenA.id === 'bnb') {
       if (tokenB.id === 'umi'){
         return hypotheticalBnbToken(value);
       }
       if (tokenB.id === 'smc') {
         return hypotheticalSmcBnbToken(value);
       }
     }
     if (tokenA.id === 'smc') {
       if (tokenB.id === 'bnb') {
         return hypotheticalSmcToken(value);
       }
       if (tokenB.id === 'usdt'){
         return hypotheticalSmcUsdtToken(value);
       }
     }
    if (tokenA.id === 'usdt') {
      if (tokenB.id === 'umi'){
        return hypotheticalUsdtUmiToken(value);
      }
      if (tokenB.id === 'smc') {
        return hypotheticalUsdtSmcToken(value);
      }
      if (tokenB.id === 'hkt') {
        return hypotheticalUsdtHktToken(value);
      }
    }
     return value;
  };

  return (
    <>
        {type === 'supply' && (
            <>
                <FormikUpdateTokenTextField
                  name="umiAmount"
                  token={asset.stakedTokenId}
                  disabled={isTransactionLoading || isSupplyingLuna}
                  value={formValues.fromTokenAmountTokens}
                  onChange={amounts =>
                      setFormValues(currentFormValues => ({
                        ...currentFormValues,
                        fromTokenAmountTokens: amounts,
                        // Reset toTokenAmount field value if users resets fromTokenAmount
                        // field value
                        toTokenAmountTokens:
                            amounts === ''
                                ? ''
                                : (calcaAmount(amounts, asset.stakedTokenId, asset.rewardTokenId)).toString(),
                        fromTokenAddress: asset.stakedTokenId.address,
                        toTokenAddress: asset.rewardTokenId.address,
                        direction: 'exactAmountIn',
                      }))
                  }
                  rightMaxButton={{
                        label: t('supplyWithdraw.max').toUpperCase(),
                        valueOnClick: maxInput.toFixed(),
                    }}
                  css={styles.input}
                    // Only display error state if amount is higher than borrow limit
                  displayableErrorCodes={[ErrorCode.HIGHER_THAN_MAX]}
                  update={new BigNumber(0)}
                />

                <Typography
                  component="div"
                  variant="small2"
                  css={[styles.greyLabel, styles.getRow({ isLast: true })]}
                >
                    <Trans
                      i18nKey={inputLabel}
                      components={{
                            White: <span css={styles.whiteLabel} />,
                        }}
                      values={{
                            amount: useConvertWeiToReadableTokenString({
                              valueWei: stakedTokenBalanceData?.balanceWei,
                                token: asset.stakedTokenId,
                            }),
                        }}
                    />
                </Typography>

                <FormikUpdateTokenTextField
                  name="amount"
                  token={asset.rewardTokenId}
                  disabled={isTransactionLoading || isSupplyingLuna}
                  value={formValues.toTokenAmountTokens}
                  update={new BigNumber(0)}
                  onChange={amounts =>
                      setFormValues(currentFormValues => ({
                        ...currentFormValues,
                        toTokenAmountTokens: amounts,
                        // Reset fromTokenAmount field value if users resets toTokenAmount
                        // field value
                        fromTokenAmountTokens:
                            amounts === ''
                                ? ''
                                : (calcaAmount(amounts, asset.rewardTokenId, asset.stakedTokenId)).toString(),
                        fromTokenAddress: asset.stakedTokenId.address,
                        toTokenAddress: asset.rewardTokenId.address,
                        direction: 'exactAmountOut',
                      }))
                  }
                  rightMaxButton={{
                        label: t('supplyWithdraw.max').toUpperCase(),
                        valueOnClick: maxInput.toFixed(),
                    }}
                  css={styles.input}
                    // Only display error state if amount is higher than borrow limit
                  displayableErrorCodes={[ErrorCode.HIGHER_THAN_MAX]}
                />
                <Typography
                  component="div"
                  variant="small2"
                  css={[styles.greyLabel, styles.getRow({ isLast: true })]}
                >
                    <Trans
                      i18nKey={inputLabel}
                      components={{
                            White: <span css={styles.whiteLabel} />,
                        }}
                      values={{
                            amount: useConvertWeiToReadableTokenString({
                              valueWei: rewardTokenBalanceData?.balanceWei,
                                token: asset.rewardTokenId,
                            }),
                        }}
                    />
                </Typography>
              <Delimiter css={styles.getRow({ isLast: false })} />


              {/* <ButtonGroup css={styles.getRow({ isLast: false })} buttonLabels={['30天', '60天', '90天']} activeButtonIndex={days} onButtonClick={(index) => setDays(arg => index)} /> */}

            </>
        )}

        {type === 'withdraw' && (
            <>
                <FormikTokenTextField
                  name="umiAmount"
                  token={TOKENS.cumi}
                  disabled={isTransactionLoading || isSupplyingLuna}
                  rightMaxButton={{
                        label: t('supplyWithdraw.max').toUpperCase(),
                        valueOnClick: maxInput.toFixed(),
                    }}
                  css={styles.input}
                    // Only display error state if amount is higher than borrow limit
                  displayableErrorCodes={[ErrorCode.HIGHER_THAN_MAX]}
                />

                <Typography
                  component="div"
                  variant="small2"
                  css={[styles.greyLabel, styles.getRow({ isLast: true })]}
                >
                    <Trans
                      i18nKey={inputLabel}
                      components={{
                            White: <span css={styles.whiteLabel} />,
                        }}
                      values={{
                            amount: useConvertWeiToReadableTokenString({
                               valueWei: userMintedUmiData ? new BigNumber(userMintedUmiData.balance) : new BigNumber(0),
                                token: TOKENS.cumi,
                            }),
                        }}
                    />
                </Typography>
            </>
        )}

      <Delimiter css={styles.getRow({ isLast: true })} />

      <LabeledInlineContent
        label={t('supplyWithdraw.dailyEarnings')}
        css={styles.getRow({ isLast: false })}
        className="info-row"
      >
        <ValueUpdate original={dailyEarningsCents} update={hypotheticalDailyEarningCents} />
      </LabeledInlineContent>
      <LabeledInlineContent
        label={t('supplyWithdraw.supplyBalance', { tokenSymbol: asset.token.symbol })}
        css={styles.getRow({ isLast: true })}
        className="info-row"
      >
        <ValueUpdate
          original={asset.walletBalance}
          update={hypotheticalTokenSupplyBalance}
          format={(value: BigNumber | undefined) =>
            formatTokensToReadableValue({
              value,
              token: asset.token,
              minimizeDecimals: true,
              addSymbol: false,
            })
          }
        />
      </LabeledInlineContent>
      <FormikSubmitButton
        fullWidth
        disabled={!validAmount || isSupplyingLuna}
        loading={isTransactionLoading}
        enabledLabel={enabledButtonKey}
        disabledLabel={disabledButtonKey}
      />
    </>
  );
};

interface SupplyWithdrawFormProps extends Omit<SupplyWithdrawFormUiProps, 'amountValue'> {
  onSubmit: AmountFormProps['onSubmit'];
}

const SupplyWithdrawForm: React.FC<SupplyWithdrawFormProps> = ({
  onSubmit,
  maxInput,
                                                                 formValues,
  ...props
}) => {
  const onSubmitHandleError: AmountFormProps['onSubmit'] = async (value: string, toTokenAmountTokens) => {
    try {
      await onSubmit(value, toTokenAmountTokens);
    } catch (error) {
      let { message } = error as Error;
      if (error instanceof VError) {
        message = formatVErrorToReadableString(error);
        toast.error({
          message,
        });
      }
    }
  };

  return (
    <AmountForm onSubmit={onSubmitHandleError} maxAmount={maxInput.toFixed()} bnbAmount={formValues.fromTokenAmountTokens} umiAmount={formValues.toTokenAmountTokens}>
      {({ values }) => (
        <SupplyWithdrawContent formValues={formValues} maxInput={maxInput} amountValue={values.amount} {...props} />
      )}
    </AmountForm>
  );
};

export default SupplyWithdrawForm;
