import { MutationObserverOptions, QueryObserverOptions, useMutation, useQuery } from 'react-query';

import getOrganization, { GetOrganizationInput, GetOrganizationOutput } from 'clients/api/queries/getOrganization';
import { useUmiTokenContract, useUmiVaultContract, useVTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import queryClient from 'clients/api/queryClient';


type Options = QueryObserverOptions<
    GetOrganizationOutput,
    Error,
    GetOrganizationOutput,
    GetOrganizationOutput,
    [FunctionKey.GET_Organization, string]
>;

const useGetOrganization = (
    { accountAddress }: Omit<GetOrganizationInput, 'UmiVaultContract'>,
    options?: Options,
) => {
  const UmiVaultContract = useUmiVaultContract();
    return useQuery(
        [FunctionKey.GET_Organization, accountAddress],
        () =>
            getOrganization({
                UmiVaultContract,
                accountAddress,
            }),
        options,
    );
};

export default useGetOrganization;
