import { MutationObserverOptions, useMutation } from 'react-query';

import { IsetOrganizationInput, IsetOrganizationOutput, queryClient, setOrganization } from 'clients/api';
import { useVaiControllerContract, useUmiTokenContract, useUmiVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
    IsetOrganizationOutput,
  Error,
  Omit<IsetOrganizationInput, 'umiControllerContract'>
>;

const useSetOrganization = (options?: Options) => {
  const umiControllerContract = useUmiVaultContract();

  return useMutation(
    FunctionKey.SetOrganization,
    (params: Omit<IsetOrganizationInput, 'umiControllerContract'>) =>
        setOrganization({
          umiControllerContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        // Invalidate queries related to fetching the user minted VAI amount
        queryClient.invalidateQueries(FunctionKey.GET_MINTED_USERINFO);
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useSetOrganization;
