import BigNumber from 'bignumber.js';

import { VBep20, VBnbToken, UmiToken, UmiVault } from 'types/contracts';

export interface GetSyndicateRateL2Input {
    UmiVaultContract: UmiVault;
  accountAddress: string;

}

export type GetSyndicateRateL2Output = {
  inviter: string;
};

const getSyndicateRateL2 = async ({
                          UmiVaultContract,
                               accountAddress,
}: GetSyndicateRateL2Input): Promise<GetSyndicateRateL2Output> => {
  const res = await UmiVaultContract.methods.syndicateRateL2().call();

  return {
    inviter: res,
  };
};

export default getSyndicateRateL2;
