import { MutationObserverOptions, useMutation } from 'react-query';

import { IsetRoiInput, IsetRoiOutput, queryClient, setRoi } from 'clients/api';
import { useVaiControllerContract, useUmiTokenContract, useUmiVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
    IsetRoiOutput,
  Error,
  Omit<IsetRoiInput, 'umiControllerContract'>
>;

const useSetRoi = (options?: Options) => {
  const umiControllerContract = useUmiVaultContract();

  return useMutation(
    FunctionKey.SetRoi,
    (params: Omit<IsetRoiInput, 'umiControllerContract'>) =>
        setRoi({
          umiControllerContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        // Invalidate queries related to fetching the user minted VAI amount
        queryClient.invalidateQueries(FunctionKey.GET_MINTED_USERINFO);
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useSetRoi;
