import BigNumber from 'bignumber.js';
import { getContractAddress } from 'utilities';

import { VenusLens, UmiVault } from 'types/contracts';

export interface GetUplineInput {
  VaultContract: UmiVault;
  accountAddress: string;
}

export type GetUplineOutput = {
  getUpline: string[];
};

const getUpline = async ({
                           VaultContract,
  accountAddress,
}: GetUplineInput): Promise<GetUplineOutput> => {
  const res = await VaultContract.methods
    .getAllUsers(accountAddress)
    .call();
  return {
    getUpline: res,
  };
};

export default getUpline;
