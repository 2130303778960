import BigNumber from 'bignumber.js';
import { TransactionCategory, TransactionEvent } from 'types';
import { convertTokensToWei, getVTokenByAddress, unsafelyGetToken } from 'utilities';

import { TOKENS } from 'constants/tokens';

import { TransactionResponse } from './types';

const formatTransaction = ({
                             value,
                             timeStamp,
                             blockHash,
  createdAt,
  updatedAt,
  category,
  event,
  ...rest
}: TransactionResponse) => ({
    ...rest,
    amountWei: new BigNumber(value),
    createdAt: new Date(timeStamp),
    updatedAt: new Date(timeStamp),
    category: category as TransactionCategory,
    event: event as TransactionEvent,
    blockHash,
  });
export default formatTransaction;
