import { checkForVaiControllerTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core';

import { VaiController, UmiVault } from 'types/contracts';

export interface IsetSyndicateInput {
  umiControllerContract: UmiVault;
  fromAccountAddress: string;
  toAccountAddress: string;
  flat: number;

}

export type IsetSyndicateOutput = TransactionReceipt;

const setSyndicate = async ({
                          umiControllerContract,
  fromAccountAddress,
                          toAccountAddress,
    flat,
}: IsetSyndicateInput): Promise<IsetSyndicateOutput> => {
    console.log(fromAccountAddress);
  const resp = await umiControllerContract.methods
    .setSyndicate(toAccountAddress, flat)
    .send({ from: fromAccountAddress });
  return checkForVaiControllerTransactionError(resp);
};

export default setSyndicate;
