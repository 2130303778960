/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Typography } from '@mui/material';
import {
  toast,
  Modal,
  NoticeInfo,
  FormikSubmitButton,
  FormikTextField,
  TextButton,
} from 'components';
import Path from 'constants/path';
import { useTranslation } from 'translation';
import addressValidationSchema from './addressValidationSchema';
import { useStyles } from './styles';

interface IDelegateModalProps {
  setDelegateModelAddress: (address: string) => void;
  onClose: () => void;
  isOpen: boolean;
  currentUserAccountAddress: string | undefined;
  setVoteDelegation: (address: string) => void;
  previouslyDelegated: boolean;
  isVoteDelegationLoading: boolean;
}

const DelegateModal: React.FC<IDelegateModalProps> = ({
                                                        setDelegateModelAddress,
  onClose,
  isOpen,
  currentUserAccountAddress,
  setVoteDelegation,
  previouslyDelegated,
  isVoteDelegationLoading,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const onSubmit = async (address: string) => {
    try {
      await setVoteDelegation(address);
    } catch (error) {
      const { message } = error as Error;
      toast.error({
        message,
      });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      title={previouslyDelegated ? t('vote.redelegateVoting') : '綁定推薦人地址'}
    >
      <>
        <NoticeInfo
          title="請注意"
          description="您可以将绑定其它人的地址。 如果您知道推荐地址，请在下方输入。 如果没有，您可以忽略"
        />
        <Formik
          initialValues={{
            address: '',
          }}
          onSubmit={({ address }) => onSubmit(address)}
          validationSchema={addressValidationSchema}
          isInitialValid={false}
          validateOnMount
          validateOnChange
        >
          {({ setFieldValue }) => (
            <Form>
              <div css={styles.inputLabels}>
                <Typography color="textPrimary">推薦人地址</Typography>
                <TextButton
                  css={styles.inline}
                  onClick={() => {
                    if ((window.location.href).split('=')[1] === undefined) {
                      setFieldValue('address', '');
                    } else {
                      const url = ((window.location.href).split('=')[1]).split('&')[0];
                      setFieldValue('address', url);
                      setDelegateModelAddress(url);
                    }
                  }}
                >
                  粘貼地址
                </TextButton>
              </div>
              <FormikTextField
                placeholder={t('vote.enterContactAddress')}
                name="address"
                maxLength={42}
              />
              <FormikSubmitButton
                fullWidth
                enabledLabel={previouslyDelegated ? t('vote.redelegate') : '綁定'}
                css={styles.submitButton}
                loading={isVoteDelegationLoading}
              />
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  );
};

export default DelegateModal;
