import { MutationObserverOptions, QueryObserverOptions, useMutation, useQuery } from 'react-query';

import getRewardsPool, { GetRewardsPoolOutput, GetRewardsPoolInput } from 'clients/api/queries/getRewardsPool';
import { useUmiTokenContract, useUmiVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import queryClient from 'clients/api/queryClient';


type Options = QueryObserverOptions<
    GetRewardsPoolOutput,
    Error,
    GetRewardsPoolOutput,
    GetRewardsPoolOutput,
    [FunctionKey.GET_REWARDS_POOL, string]
>;

const useGetRewardsPool = (
    { accountAddress }: Omit<GetRewardsPoolInput, 'UmiVaultContract'>,
    options?: Options,
) => {
  const UmiVaultContract = useUmiVaultContract();
    return useQuery(
        [FunctionKey.GET_REWARDS_POOL, accountAddress],
        () =>
            getRewardsPool({
                UmiVaultContract,
                accountAddress,
            }),
        options,
    );
};

export default useGetRewardsPool;
