import BigNumber from 'bignumber.js';

import { VBep20, VBnbToken, UmiToken } from 'types/contracts';

export interface GetInviterInput {
  UmiTokenContract: UmiToken;
  accountAddress: string;

}

export type GetInviterOutput = {
  inviter: string;
};

const getInviter = async ({
                               UmiTokenContract,
                               accountAddress,
}: GetInviterInput): Promise<GetInviterOutput> => {
  const res = await UmiTokenContract.methods.inviter(accountAddress).call();

  return {
    inviter: res,
  };
};

export default getInviter;
