import { MutationObserverOptions, QueryObserverOptions, useMutation, useQuery } from 'react-query';

import getSyndicateRateL1, { GetSyndicateRateL1Input, GetSyndicateRateL1Output } from 'clients/api/queries/getSyndicateRateL1';
import { useUmiTokenContract, useUmiVaultContract, useVTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import queryClient from 'clients/api/queryClient';


type Options = QueryObserverOptions<
    GetSyndicateRateL1Output,
    Error,
    GetSyndicateRateL1Output,
    GetSyndicateRateL1Output,
    [FunctionKey.GET_SYNDICATERATEL1, string]
>;

const useSyndicateRateL1 = (
    { accountAddress }: Omit<GetSyndicateRateL1Input, 'UmiVaultContract'>,
    options?: Options,
) => {
  const UmiVaultContract = useUmiVaultContract();
    return useQuery(
        [FunctionKey.GET_SYNDICATERATEL1, accountAddress],
        () =>
            getSyndicateRateL1({
                UmiVaultContract,
                accountAddress,
            }),
        options,
    );
};

export default useSyndicateRateL1;
