import { QueryObserverOptions, useQuery } from 'react-query';

import { useVenusLensContract, useUmiVaultContract } from 'clients/contracts/hooks';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

import GetVaultLevel, { GetVaultInput, GetVaultLevelOutput } from '.';


type Options = QueryObserverOptions<
    GetVaultLevelOutput,
  Error,
    GetVaultLevelOutput,
    GetVaultLevelOutput,
  [FunctionKey.GET_XVS_GETLEVEL, string]
>;

const useGetVaultLevel = (
  { accountAddress }: Omit<GetVaultInput, 'VaultContract'>,
  options?: Options,
) => {
  const VaultContract = useUmiVaultContract();

  return useQuery(
    [FunctionKey.GET_XVS_GETLEVEL, accountAddress],
    () =>
        GetVaultLevel({
          VaultContract,
        accountAddress,
      }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
    },
  );
};

export default useGetVaultLevel;
