import BigNumber from 'bignumber.js';

import { VBep20, VBnbToken, UmiToken, UmiVault } from 'types/contracts';

export interface GetCumiPoolInput {
    UmiVaultContract: UmiVault;
  accountAddress: string;

}

export type GetCumiPoolOutput = {
    Amount: string;
};

const getCumiPool = async ({
                                  UmiVaultContract,
                               accountAddress,
}: GetCumiPoolInput): Promise<GetCumiPoolOutput> => {
  const res = await UmiVaultContract.methods.cumiPool().call();

  return {
      Amount: res,
  };
};

export default getCumiPool;
