import { checkForVaiControllerTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core';
import { VaiController, UmiVault } from 'types/contracts';

export interface IsetRoiInput {
  umiControllerContract: UmiVault;
  fromAccountAddress: string;
  amountWei: string;
}

export type IsetRoiOutput = TransactionReceipt;

const setRoi = async ({
                          umiControllerContract,
  fromAccountAddress,
  amountWei,
}: IsetRoiInput): Promise<IsetRoiOutput> => {
  const resp = await umiControllerContract.methods
    .setRoi(amountWei)
    .send({ from: fromAccountAddress });
  return checkForVaiControllerTransactionError(resp);
};

export default setRoi;
