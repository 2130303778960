import { QueryObserverOptions, useQuery } from 'react-query';

import { useUmiVaultContract } from 'clients/contracts/hooks';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

import getUmiPrice, { GetUmiPriceInput, GetUmiPriceOutput } from '.';

type Options = QueryObserverOptions<
    GetUmiPriceOutput,
  Error,
    GetUmiPriceOutput,
    GetUmiPriceOutput,
  [FunctionKey.GET_UMI_UMIPRICE, string]
>;

const useGetUmiPrice = (
  { accountAddress }: Omit<GetUmiPriceInput, 'VaultContract'>,
  options?: Options,
) => {
  const VaultContract = useUmiVaultContract();

  return useQuery(
    [FunctionKey.GET_UMI_UMIPRICE, accountAddress],
    () =>
        getUmiPrice({
          VaultContract,
        accountAddress,
      }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
    },
  );
};

export default useGetUmiPrice;
