/** @jsxImportSource @emotion/react */
import { Paper, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import React, { useContext } from 'react';
import { useTranslation } from 'translation';
import { formatCentsToReadableValue, formatToReadablePercentage } from 'utilities';

import { useGetTreasuryTotals, useGetMintedUmi, GetMintedUmiOutput } from 'clients/api';

import { Cell, CellGroup } from 'components';
import { AuthContext } from 'context/AuthContext';
import { useStyles } from '../styles';
import TEST_IDS from '../testIds';

interface HeaderProps {
  totalSupplyCents: BigNumber;
  totalBorrowCents: BigNumber;
  availableLiquidityCents: BigNumber;
  totalTreasuryCents: BigNumber;
  userMintedUmiData?: GetMintedUmiOutput;
}

export const HeaderUi: React.FC<HeaderProps> = ({
  totalSupplyCents,
  totalBorrowCents,
  availableLiquidityCents,
  totalTreasuryCents,
                                                  userMintedUmiData,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const netApyPercentage = userMintedUmiData?.stake ? +userMintedUmiData.stake : 0.00;
  const dailyEarningsCents = userMintedUmiData?.arp ? +userMintedUmiData.arp : 0.00;
  const balance = userMintedUmiData?.balance ? +userMintedUmiData.balance : 0.00;
  const Rewards = userMintedUmiData?.Rewards ? +userMintedUmiData.Rewards : 0.00;


  const cells: Cell[] = [
    {
      label: t('account.accountSummary.netApy'),
      value: formatCentsToReadableValue({ value: netApyPercentage }),
      tooltip: t('account.accountSummary.netApyTooltip'),
      color: styles.getNetApyColor({ netApyPercentage }),
    },
    {
      label: t('account.accountSummary.dailyEarnings'),
      value: formatCentsToReadableValue({ value: dailyEarningsCents }),
    },
    {
      label: t('account.accountSummary.totalSupply'),
      value: formatCentsToReadableValue({ value: balance }),
    },
    {
      label: t('account.accountSummary.totalBorrow'),
      value: formatCentsToReadableValue({ value: 0 }),
    },
    {
      label: t('account.accountSummary.ReferralRewards'),
      value: formatCentsToReadableValue({ value: Rewards }),
    },
    {
      label: t('account.accountSummary.TaxLevel'),
      value: formatCentsToReadableValue({ value: 0 }),
    },
  ];
  return (
      <div css={styles.section}>
        {/* <div css={styles.sectionTitle}> */}
        {/*   <Typography variant="h3" css={styles.sectionTitleText}> */}
        {/*     {t('account.accountSummary.title')} */}
        {/*   </Typography> */}
        {/* </div> */}

        <CellGroup cells={cells} data-testid={TEST_IDS.stats} />
      </div>
    // <Paper css={styles.headerRoot}>
    //   <div css={styles.row}>
    //     <Paper css={styles.box}>
    //       <Typography variant="small1" css={styles.title}>
    //         {t('market.totalSupply')}
    //       </Typography>
    //       <Typography variant="h3" css={styles.value}>
    //         {formatCentsToReadableValue({ value: totalSupplyCents })}
    //       </Typography>
    //     </Paper>
    //     <Paper css={styles.box}>
    //       <Typography variant="small1" css={styles.title}>
    //         {t('market.totalBorrow')}
    //       </Typography>
    //       <Typography variant="h3" css={styles.value}>
    //         {formatCentsToReadableValue({ value: totalBorrowCents })}
    //       </Typography>
    //     </Paper>
    //     <Paper css={styles.box}>
    //       <Typography variant="small1" css={styles.title}>
    //         {t('market.availableLiquidity')}
    //       </Typography>
    //       <Typography variant="h3" css={styles.value}>
    //         {formatCentsToReadableValue({ value: availableLiquidityCents })}
    //       </Typography>
    //     </Paper>
    //     <Paper css={styles.box}>
    //       <Typography variant="small1" css={styles.title}>
    //         {t('market.totalTreasury')}
    //       </Typography>
    //       <Typography variant="h3" css={styles.value}>
    //         {formatCentsToReadableValue({ value: totalTreasuryCents })}
    //       </Typography>
    //     </Paper>
    //   </div>
    // </Paper>
  );
};

const Header = () => {
  const { account } = useContext(AuthContext);

  // TODO: handle loading state (see https://app.clickup.com/t/2d4rcee)
  const {
    data: {
      treasuryTotalSupplyBalanceCents,
      treasuryTotalBorrowBalanceCents,
      treasuryTotalAvailableLiquidityBalanceCents,
      treasuryTotalBalanceCents,
    },
  } = useGetTreasuryTotals();

  const { data: userMintedUmiData, isLoading: isGetUserMintedUmiLoading } = useGetMintedUmi(
      {
        accountAddress: account?.address || '',
      },
      {
        enabled: !!account?.address,
      },
  );


  const totalSupplyCents = 0;
  const totalBorrowCents = 0;
  return (
    <HeaderUi
      totalSupplyCents={treasuryTotalSupplyBalanceCents}
      totalBorrowCents={treasuryTotalBorrowBalanceCents}
      availableLiquidityCents={treasuryTotalAvailableLiquidityBalanceCents}
      totalTreasuryCents={treasuryTotalBalanceCents}
      userMintedUmiData={userMintedUmiData}
    />
  );
};

export default Header;
