import BigNumber from 'bignumber.js';

import { VBep20, VBnbToken, UmiToken, UmiVault } from 'types/contracts';

export interface GetRoiInput {
    UmiVaultContract: UmiVault;
  accountAddress: string;

}

export type GetRoiOutput = {
  inviter: string;
};

const getRoi = async ({
                          UmiVaultContract,
                               accountAddress,
}: GetRoiInput): Promise<GetRoiOutput> => {
  const res = await UmiVaultContract.methods.roi().call();

  return {
    inviter: res,
  };
};

export default getRoi;
