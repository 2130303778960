import { MutationObserverOptions, QueryObserverOptions, useMutation, useQuery } from 'react-query';

import getOutroi, { GetOutroiInput, GetOutroiOutput } from 'clients/api/queries/getOutroi';
import { useUmiTokenContract, useUmiVaultContract, useVTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import queryClient from 'clients/api/queryClient';


type Options = QueryObserverOptions<
    GetOutroiOutput,
    Error,
    GetOutroiOutput,
    GetOutroiOutput,
    [FunctionKey.GET_OUTROI, string]
>;

const useGetOutroi = (
    { accountAddress }: Omit<GetOutroiInput, 'UmiVaultContract'>,
    options?: Options,
) => {
  const UmiVaultContract = useUmiVaultContract();
    return useQuery(
        [FunctionKey.GET_OUTROI, accountAddress],
        () =>
            getOutroi({
                UmiVaultContract,
                accountAddress,
            }),
        options,
    );
};

export default useGetOutroi;
