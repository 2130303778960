import BigNumber from 'bignumber.js';

import { Comptroller, UmiVault } from 'types/contracts';

export interface GetMintedUmiInput {
  comptrollerContract: UmiVault;
  accountAddress: string;
}

export type GetMintedUmiOutput = {
  balance: string;
  arp: string;
  stake: string;
  endClaimedDates: string;
  mintedVaiWei: string;
  firstClaimedDates: string;
  Rewards: string;
  syndicate: string;

  ClaimedDates: string;
};

const getMintedUmi = async ({
  comptrollerContract,
  accountAddress,
}: GetMintedUmiInput): Promise<GetMintedUmiOutput> => {
  const res = await comptrollerContract.methods.userInfo(accountAddress).call();

  return {
    balance: res.balance,
    arp: res.arp,
    stake: res.stake,
    endClaimedDates: res.endClaimedDates,
    mintedVaiWei: res.Rewards,
    firstClaimedDates: res.firstClaimedDates,
    Rewards: res.Rewards,
    syndicate: res.syndicate,
    ClaimedDates: res.ClaimedDates,
  };
};

export default getMintedUmi;
