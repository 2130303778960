import * as React from 'react';
import { SVGProps } from 'react';

const UmiLogoMobile = (props: SVGProps<SVGSVGElement>) => {
    const { id = 'UMI' } = props;
    const gradientId = `gradient_${id}`;
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle className="st00" cx="30" cy="30" r="30" />
            <linearGradient
              id="paint0_linear"
              gradientUnits="userSpaceOnUse"
              x1="21.1834"
              y1="29.3077"
              x2="52.0962"
              y2="29.3077"
            >
                <stop offset="0" stopColor="stop-color:#FFF" />
                <stop offset="1" stopColor="stop-color:#FFF" />
            </linearGradient>
            <path
              className="st11"
              d="M51.8,37.2c-1.8-1.2-8.9-5.4-16.4-1.1c-0.2,0.1-0.5-0.1-0.4-0.3c1.4-2.6,5.6-10.8,4.6-17.1c0,0-0.1-5.4-5.9-8.5c-0.2-0.1-0.5-0.1-0.7,0c-2.3,1.3-15.2,9.2-11,26.8c0,0-0.6-11.8,10.3-19.8c0,0,7.4,6.7-5.3,26.4c0,0,8.2-7.1,18.1-4.7c0,0-1.7,5.7-16.1,8.5c0,0,10.3,2.7,16.1-1.1c4.8-3.1,6.4-7,6.9-8.4C52.2,37.7,52,37.4,51.8,37.2z"
              fill="url(#paint0_linear)"
            />
            <linearGradient
              id="paint0_linear"
              gradientUnits="userSpaceOnUse"
              x1="8.8291"
              y1="29.083"
              x2="21.8147"
              y2="42.0687"
            >
                <stop offset="0" stopColor="stop-color:#FFF" />
                <stop offset="1" stopColor="stop-color:#FFF" />
            </linearGradient>
            <path
              className="st22"
              d="M13.1,30.1c-0.9-0.5-1.9-0.9-2.9-1C9.6,29,9,29,8.6,29.3c-0.8,0.7-0.8,2-0.5,2.9c0.4,1.1,1.5,1.7,2.6,2c3.1,0.8,7.7,2.7,11.3,7.6C22.1,41.8,19.8,33.5,13.1,30.1z"
              fill="url(#paint0_linear)"
            />
            <linearGradient
              id="paint0_linear"
              gradientUnits="userSpaceOnUse"
              x1="12.0818"
              y1="24.1649"
              x2="18.9465"
              y2="31.0295"
            >
                <stop offset="0" stopColor="stop-color:#FFF" />
                <stop offset="1" stopColor="stop-color:#FFF" />
            </linearGradient>
            <path
              className="st33"
              d="M12.2,27c0.3,0.2,0.6,0.2,0.9,0.3c0.4,0.1,0.7,0.1,1.1,0.2c0.5,0.1,1,0.3,1.5,0.5c0.5,0.3,0.9,0.6,1.4,0.9c1.6,1,2.1,1.9,2.1,1.9c-0.1-0.5-0.3-0.9-0.5-1.3c-0.4-1-0.8-1.9-1.4-2.8c-0.7-1.1-1.7-2-2.9-2.6c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.1-0.7-0.3-1-0.3c-1,0-1.8,0.8-1.8,1.8C11.4,26.2,11.7,26.7,12.2,27z"
              fill="url(#paint0_linear)"
            />
            <linearGradient
              id="paint0_linear"
              gradientUnits="userSpaceOnUse"
              x1="20.985"
              y1="46.8983"
              x2="26.874"
              y2="46.8983"
            >
                <stop offset="0" stopColor="stop-color:#FFF" />
                <stop offset="1" stopColor="stop-color:#FFF" />
            </linearGradient>
            <path
              className="st44"
              d="M23.9,44c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9C26.9,45.3,25.6,44,23.9,44z"
              fill="url(#paint0_linear)"
            />
        </svg>);
};

export default UmiLogoMobile;
