/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo, useState, useRef, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { TOKENS } from 'constants/tokens';

import {
    useGetCurrentVotes,
    useGetVoteDelegateAddress,
    useSetInviter, GetVoteDelegateAddressOutput, useGetInviter, GetInviterOutput, useGetVaultLevel, useGetUpline, GetVaultLevelOutput, GetUplineOutput,
} from 'clients/api';
import { AuthContext } from 'context/AuthContext';
import { Delimiter, EllipseText, Icon, LinkButton, PrimaryButton, Tooltip } from 'components';
import PATHS from 'constants/path';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';
import { useTranslation } from 'translation';
import { convertWeiToTokens, restService } from 'utilities';
import DelegateModal from '../DelegateModal';
import { useStyles } from './styles';


interface IVotingWalletUiProps {
    connectedWallet: boolean;
    openAuthModal: () => void;
    currentUserAccountAddress: string | undefined;

    delegateModelIsOpen: boolean;
    setDelegateModelIsOpen: (open: boolean) => void;
    setDelegateModelAddress: (address: string) => void;
    delegate: GetInviterOutput | undefined;
    setVoteDelegation: (address: string) => void;
    isVoteDelegationLoading: boolean;
    Level: GetVaultLevelOutput | undefined;
    Upline: GetUplineOutput | undefined;
}

export const VotingWalletUi: React.FC<IVotingWalletUiProps> = ({
                                                                   connectedWallet,
                                                                   openAuthModal,
                                                                   currentUserAccountAddress,
  delegateModelIsOpen,
  setDelegateModelIsOpen,
                                                                   setDelegateModelAddress,
                                                                   delegate,
                                                                   setVoteDelegation,
                                                                   isVoteDelegationLoading,
    Level, Upline,
}) => {
  const { t, Trans } = useTranslation();
  const styles = useStyles();


  const previouslyDelegated = !!delegate;
  // const userHasLockedXVS = userStakedWei.isGreaterThan(0);
    return (
    <div css={styles.root}>
      <Typography variant="h4">綁定推薦人</Typography>
      <Paper css={styles.votingWalletPaper}>
          {delegate?.inviter !== '0x0000000000000000000000000000000000000000' && (
              <div css={styles.votingWeightContainer}>
                  <Typography variant="body2" css={styles.subtitle}>
                      您的推薦
                  </Typography>
                  <Typography className="ellipse-text" css={styles.address}>
                      {delegate?.inviter}
                  </Typography>
              </div>
          )}
          <div css={styles.votingWeightContainer}>
              <Typography variant="body2" css={styles.subtitle}>
                  Tax Level
              </Typography>
              <Typography className="ellipse-text" css={styles.address}>
                  {Level?.level.toFixed()}
              </Typography>
          </div>
        <Delimiter css={styles.delimiter} />
          <div css={styles.votingWeightContainer}>
              <Typography variant="body2" css={styles.subtitle}>
                  直推人數
              </Typography>
              <Typography className="ellipse-text" css={styles.address}>
                  {Upline?.getUpline ? Upline?.getUpline.length : 0 }
              </Typography>
          </div>
        {!connectedWallet && (
          <PrimaryButton css={styles.actionButton} onClick={openAuthModal}>
            {t('connectWallet.connectButton')}
          </PrimaryButton>
        )}
        {/* {connectedWallet && !userHasLockedXVS && ( */}
        {/*   <LinkButton css={styles.actionButton} to={PATHS.VAULT}> */}
        {/*     {t('vote.depositXvs')} */}
        {/*   </LinkButton> */}
        {/* )} */}
        {connectedWallet && (
          <PrimaryButton css={styles.actionButton} onClick={() => setDelegateModelIsOpen(true)} disabled={previouslyDelegated && delegate?.inviter !== '0x0000000000000000000000000000000000000000'}>
            {previouslyDelegated && delegate?.inviter !== '0x0000000000000000000000000000000000000000' ? t('vote.redelegate') : '綁定'}
          </PrimaryButton>
        )}
      </Paper>
      <DelegateModal
        setDelegateModelAddress={setDelegateModelAddress}
        onClose={() => setDelegateModelIsOpen(false)}
        isOpen={delegateModelIsOpen}
        currentUserAccountAddress={currentUserAccountAddress}
        previouslyDelegated={previouslyDelegated}
        setVoteDelegation={setVoteDelegation}
        isVoteDelegationLoading={isVoteDelegationLoading}
      />
    </div>
  );
};

const VotingWallet: React.FC = () => {
    const { account } = useContext(AuthContext);
    // TODO: handle loading state (see https://app.clickup.com/t/2d4rcee)
    // const {
    //   data: { assets },
    // } = useGetUserMarketInfo({
    //   accountAddress: account?.address,
    // });
    const { account: { address: accountAddress } = { address: undefined }, openAuthModal } =
        useContext(AuthContext);

    const { data: delegate } = useGetInviter(
        { accountAddress: accountAddress || '' },
        { enabled: !!accountAddress },
    );

    const { data: Level } = useGetVaultLevel(
        { accountAddress: accountAddress || '' },
        { enabled: !!accountAddress },
    );

    const { data: Upline } = useGetUpline(
        { accountAddress: accountAddress || '' },
        { enabled: !!accountAddress },
    );

  const [delegateModelIsOpen, setDelegateModelIsOpen] = useState(false);
  const [delegateModelAddress, setDelegateModelAddress] = useState('');
  const { t } = useTranslation();
    const userStakedWei = new BigNumber(0);
    const XVS_TOKEN_ID = 'umi';
    const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();
    const { mutateAsync: setVoteDelegation, isLoading: isVoteDelegationLoading } = useSetInviter(
        {
            onSuccess: data => {
                setDelegateModelIsOpen(false);
                // openSuccessfulTransactionModal({
                //     title: t('vote.successfulDelegationModal.title'),
                //     content: t('vote.successfulDelegationModal.message'),
                //     amount: {
                //         tokenId: TOKENS.umi,
                //         valueWei: userStakedWei,
                //     },
                //     transactionHash: data.transactionHash,
                // });
            },
        },
    );

  return (
      <>
          <VotingWalletUi
            connectedWallet={!!accountAddress}
            openAuthModal={openAuthModal}
            currentUserAccountAddress={accountAddress}
            delegateModelIsOpen={delegateModelIsOpen}
            setDelegateModelIsOpen={setDelegateModelIsOpen}
            setDelegateModelAddress={setDelegateModelAddress}
            setVoteDelegation={(delegateAddress: string) =>
                setVoteDelegation({ delegateAddress, accountAddress: accountAddress || '' })
            }
            isVoteDelegationLoading={isVoteDelegationLoading}
            delegate={delegate}
            Level={Level}
            Upline={Upline}
          />
          {/* <Table inviter={getInviterData.inviters} /> */}
      </>

  );
};

export default VotingWallet;
