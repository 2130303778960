import { checkForVaiControllerTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core';

import { VaiController, UmiVault } from 'types/contracts';

export interface RepayAirdropInput {
  umiControllerContract: UmiVault;
  fromAccountAddress: string;
}

export type IRepayAirdropOutput = TransactionReceipt;

const repayAirdrop = async ({
                          umiControllerContract,
  fromAccountAddress,
}: RepayAirdropInput): Promise<IRepayAirdropOutput> => {
  const resp = await umiControllerContract.methods
    .airdrop(fromAccountAddress)
    .send({ from: fromAccountAddress });
  return checkForVaiControllerTransactionError(resp);
};

export default repayAirdrop;
