import BigNumber from 'bignumber.js';

import { VBep20, VBnbToken, UmiToken, UmiVault } from 'types/contracts';

export interface GetRewardsPoolInput {
    UmiVaultContract: UmiVault;
  accountAddress: string;

}

export type GetRewardsPoolOutput = {
  inviter: string;
};

const getRewardsPool = async ({
                                  UmiVaultContract,
                               accountAddress,
}: GetRewardsPoolInput): Promise<GetRewardsPoolOutput> => {
  const res = await UmiVaultContract.methods.rewardsPool().call();

  return {
    inviter: res,
  };
};

export default getRewardsPool;
