import { MutationObserverOptions, useMutation } from 'react-query';

import { MintUmiInput, MintUmiOutput, mintUmi, queryClient } from 'clients/api';
import { useUmiVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<MintUmiOutput,
  Error,
  Omit<MintUmiInput, 'umiVaultContract'>
>;

const useMintUmi = (options?: Options) => {
  const umiVaultContract = useUmiVaultContract();

    return useMutation(
    FunctionKey.MINT_UMI,
    (params: Omit<MintUmiInput, 'umiVaultContract'>) =>
        mintUmi({
          umiVaultContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        // Invalidate queries related to fetching the user minted VAI amount
        queryClient.invalidateQueries(FunctionKey.GET_MINTED_UMI);
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useMintUmi;
