/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import React, { useMemo } from 'react';
import { Asset } from 'types';

import { GetUserNumberOutput, useGetUserMarketInfo, useGetUserNumber, useGetVaults } from 'clients/api';
import { TOKENS } from 'constants/tokens';
import { AuthContext } from 'context/AuthContext';

import Markets from './Markets';
import MintRepayVai from './MintRepayVai';
import MyAccount from './MyAccount';
import { useStyles } from './styles';
import Header from './Header';
import VotingWallet from './VotingWallet';
import HigherRiskTokensNotice from './HigherRiskTokensNotice';

interface DashboardUiProps {
  accountAddress: string;
  userTotalBorrowLimitCents: BigNumber;
  userTotalBorrowBalanceCents: BigNumber;
  userTotalSupplyBalanceCents: BigNumber;
  usernumber?: GetUserNumberOutput;
  assets: Asset[];
}

const DashboardUi: React.FC<DashboardUiProps> = ({
  accountAddress,
  assets,
    usernumber,
  userTotalBorrowLimitCents,
  userTotalBorrowBalanceCents,
  userTotalSupplyBalanceCents,
}) => {
  const styles = useStyles();
    const { account } = React.useContext(AuthContext);
    const [isXvsEnabled, setIsXvsEnabled] = React.useState(true);
    const { data: vaults, isLoading: isGetVaultsLoading } = useGetVaults({
        accountAddress: account?.address,
    });
  const { suppliedAssets, supplyMarketAssets, borrowingAssets, borrowMarketAssets } =
    useMemo(() => {
      const sortedAssets = assets.reduce(
        (acc, curr) => {
          if (curr.supplyBalance.isGreaterThan(0)) {
            acc.suppliedAssets.push(curr);
          } else {
            acc.supplyMarketAssets.push(curr);
          }

          if (curr.borrowBalance.isGreaterThan(0)) {
            acc.borrowingAssets.push(curr);
          } else if (curr.token.address !== TOKENS.xvs.address) {
            acc.borrowMarketAssets.push(curr);
          }
          return acc;
        },
        {
          suppliedAssets: [] as Asset[],
          supplyMarketAssets: [] as Asset[],
          borrowingAssets: [] as Asset[],
          borrowMarketAssets: [] as Asset[],
        },
      );
      return sortedAssets;
    }, [JSON.stringify(assets)]);

  return (
    <>
      <div css={styles.row}>
          {/* <HigherRiskTokensNotice /> */}

          <MyAccount
            assets={vaults}
            setIsXvsEnabled={setIsXvsEnabled}
            isXvsEnabled={isXvsEnabled}
            css={styles.column}
            usernumber={usernumber}
            userTotalBorrowLimitCents={userTotalBorrowLimitCents}
            userTotalBorrowBalanceCents={userTotalBorrowBalanceCents}
            userTotalSupplyBalanceCents={userTotalSupplyBalanceCents}
          />

        <MintRepayVai css={styles.column} />
      </div>
        <Header css={styles.header} />
        <VotingWallet />

        {/* <Markets */}
        {/*   isXvsEnabled={isXvsEnabled} */}
        {/*   accountAddress={accountAddress} */}
        {/*   userTotalBorrowLimitCents={userTotalBorrowLimitCents} */}
        {/*   suppliedAssets={suppliedAssets} */}
        {/*   supplyMarketAssets={supplyMarketAssets} */}
        {/*   borrowingAssets={borrowingAssets} */}
        {/*   borrowMarketAssets={borrowMarketAssets} */}
        {/* /> */}
    </>
  );
};

const Dashboard: React.FC = () => {
  const { account } = React.useContext(AuthContext);
  const accountAddress = account?.address || '';
  // TODO: handle loading state (see https://app.clickup.com/t/2d4rcee)
  const {
    data: {
      assets,
      userTotalBorrowLimitCents,
      userTotalBorrowBalanceCents,
      userTotalSupplyBalanceCents,
    },
  } = useGetUserMarketInfo({
    accountAddress,
  });

    const { data: number } = useGetUserNumber();

    console.log(number);

  return (
      <DashboardUi
        accountAddress={accountAddress}
        assets={assets}
        usernumber={number}
        userTotalBorrowLimitCents={userTotalBorrowLimitCents}
        userTotalBorrowBalanceCents={userTotalBorrowBalanceCents}
        userTotalSupplyBalanceCents={userTotalSupplyBalanceCents}
      />

  );
};

export default Dashboard;
