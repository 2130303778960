import { MutationObserverOptions, useMutation } from 'react-query';

import { SetSyndicateRateL1Input, SetSyndicateRateL1Output, queryClient, setSyndicateRateL1 } from 'clients/api';
import { useVaiControllerContract, useUmiTokenContract, useUmiVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
    SetSyndicateRateL1Output,
  Error,
  Omit<SetSyndicateRateL1Input, 'umiControllerContract'>
>;

const useSetSyndicateRateL1 = (options?: Options) => {
  const umiControllerContract = useUmiVaultContract();

  return useMutation(
    FunctionKey.SET_SYNDICATERATEL1,
    (params: Omit<SetSyndicateRateL1Input, 'umiControllerContract'>) =>
        setSyndicateRateL1({
          umiControllerContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        // Invalidate queries related to fetching the user minted VAI amount
        queryClient.invalidateQueries(FunctionKey.GET_MINTED_USERINFO);
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useSetSyndicateRateL1;
