import { MutationObserverOptions, QueryObserverOptions, useMutation, useQuery } from 'react-query';

import getCumiPool, { GetCumiPoolInput, GetCumiPoolOutput } from 'clients/api/queries/getCumiPool';
import { useUmiTokenContract, useUmiVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import queryClient from 'clients/api/queryClient';


type Options = QueryObserverOptions<
    GetCumiPoolOutput,
    Error,
    GetCumiPoolOutput,
    GetCumiPoolOutput,
    [FunctionKey.GET_CUMI_POOL, string]
>;

const useGetCumiPool = (
    { accountAddress }: Omit<GetCumiPoolInput, 'UmiVaultContract'>,
    options?: Options,
) => {
  const UmiVaultContract = useUmiVaultContract();
    return useQuery(
        [FunctionKey.GET_CUMI_POOL, accountAddress],
        () =>
            getCumiPool({
                UmiVaultContract,
                accountAddress,
            }),
        options,
    );
};

export default useGetCumiPool;
