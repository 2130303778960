import { MutationObserverOptions, QueryObserverOptions, useMutation, useQuery } from 'react-query';

import getRoi, { GetRoiInput, GetRoiOutput } from 'clients/api/queries/getRoi';
import { useUmiTokenContract, useUmiVaultContract, useVTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import queryClient from 'clients/api/queryClient';


type Options = QueryObserverOptions<
    GetRoiOutput,
    Error,
    GetRoiOutput,
    GetRoiOutput,
    [FunctionKey.GET_ROI, string]
>;

const useGetRoi = (
    { accountAddress }: Omit<GetRoiInput, 'UmiVaultContract'>,
    options?: Options,
) => {
  const UmiVaultContract = useUmiVaultContract();
    return useQuery(
        [FunctionKey.GET_ROI, accountAddress],
        () =>
            getRoi({
                UmiVaultContract,
                accountAddress,
            }),
        options,
    );
};

export default useGetRoi;
