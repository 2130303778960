import BigNumber from 'bignumber.js';

import { UmiVault } from 'types/contracts';

export interface GetSndicateGloablAmountInput {
    UmiVaultContract: UmiVault;
  accountAddress: string;

}

export type GetSndicateGloablAmountOutput = {
  Amount: string;
};

const getSndicateGloablAmount = async ({
                              UmiVaultContract,
                               accountAddress,
}: GetSndicateGloablAmountInput): Promise<GetSndicateGloablAmountOutput> => {
  const res = await UmiVaultContract.methods.syndicateGloblAmount().call();

  return {
      Amount: res,
  };
};

export default getSndicateGloablAmount;
