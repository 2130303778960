import * as React from 'react';
import { SVGProps } from 'react';

const UmiLogoMobile = (props: SVGProps<SVGSVGElement>) => {
    const { id = 'UmiLogoMobile' } = props;
    const gradientId = `gradient_${id}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 50.71 12" {...props}>
            <path
              d="M1.21.1h0a1.32,1.32,0,0,1,1.3,1.3l-.1,5a2.73,2.73,0,0,0,2.8,2.8h11.2a2.8,2.8,0,0,0,2.8-2.8V1.3A1.32,1.32,0,0,1,20.51,0h0a1.32,1.32,0,0,1,1.3,1.3v5a5.38,5.38,0,0,1-5.4,5.4H5.21C2,11.7,0,9.1,0,5.9V1.4A1.18,1.18,0,0,1,1.21.1ZM45,.1a1.14,1.14,0,0,0-.8.3h0l-3.3,2.5a10.54,10.54,0,0,1-12,0l-3-2.4a1.16,1.16,0,0,0-1-.4,1.32,1.32,0,0,0-1.3,1.3v9.1a1.3,1.3,0,0,0,2.6,0V4.1l.6.4a12.86,12.86,0,0,0,16.6-.1l.4-.4v6.5a1.3,1.3,0,1,0,2.6,0V1.4A1.49,1.49,0,0,0,45,.1ZM34.91,8.8a1.6,1.6,0,1,0,1.6,1.6A1.52,1.52,0,0,0,34.91,8.8Zm15.8,1.7v-9A1.32,1.32,0,0,0,49.41.2h0a1.32,1.32,0,0,0-1.3,1.3v9a1.32,1.32,0,0,0,1.3,1.3h0A1.32,1.32,0,0,0,50.71,10.5Z"
              fill={`url(#${gradientId})`}
            />
            <defs>
                <linearGradient
                  id={gradientId}
                  x1="-0.08"
                  y1="6.09"
                  x2="50.7"
                  y2="6.09"
                  gradientTransform="matrix(1, 0, 0, -1, 0, 12.09)"
                  gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#f54607" />
                    <stop offset="1" stopColor="#f5b14a" />
                </linearGradient>
            </defs>

        </svg>
    );
};

export default UmiLogoMobile;
