import { useField } from 'formik';
import React, { useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';

import { TokenTextField, TokenTextFieldProps } from '../TokenTextField';


interface FormikUpdateTokenTextFieldProps
  extends Omit<TokenTextFieldProps, 'name'> {
  name: string;
  update: BigNumber | undefined | void;
  displayableErrorCodes?: string[];
}

export const FormikUpdateTokenTextField = ({
  name,
                                             update,
                                             value,
                                             onChange,
  displayableErrorCodes = [],
  ...rest
}: FormikUpdateTokenTextFieldProps) => {
  // const [{ value, onBlur }, { error }, { setValue }] = useField(name);
  const setMaxValue = (val: string) => {
    if (onChange) {
      onChange(val);
    }
  };
  return (
    <TokenTextField
      name={name}
      value={value}
      onChange={onChange}
      // onBlur={onBlur}
      // hasError={!!(error && displayableErrorCodes.includes(error))}
      {...rest}
    />
  );
};
