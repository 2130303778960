import { QueryObserverOptions, useQuery } from 'react-query';

import getMintedUmi, {
  GetMintedUmiInput,
  GetMintedUmiOutput,
} from 'clients/api/queries/getMintedUmi';
import { useComptrollerContract, useUmiVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
    GetMintedUmiOutput,
  Error,
    GetMintedUmiOutput,
    GetMintedUmiOutput,
  FunctionKey.GET_MINTED_USERIFNO
>;

const useGetMintedUmi = (
  { accountAddress }: Omit<GetMintedUmiInput, 'comptrollerContract'>,
  options?: Options,
) => {
  const comptrollerContract = useUmiVaultContract();

  return useQuery(
    FunctionKey.GET_MINTED_USERIFNO,
    () => getMintedUmi({ accountAddress, comptrollerContract }),
    options,
  );
};

export default useGetMintedUmi;
