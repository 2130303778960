/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { useGetVaults } from 'clients/api';
import Header from './Header';
import MarketTable from './MarketTable';
import Markets from './Markets';
import { AuthContext } from '../../context/AuthContext';
import { useGetUserMarketInfo } from '../../clients/api';
import { TOKENS } from '../../constants/tokens';
import { Asset, Vault } from '../../types';


const MarketUi: React.FC = () => {
    const { account } = React.useContext(AuthContext);
    const [isXvsEnabled, setIsXvsEnabled] = React.useState(true);
    const accountAddress = account?.address || '';
    const { data: vaults, isLoading: isGetVaultsLoading } = useGetVaults({
        accountAddress: account?.address,
    });
    const {
        data: {
            assets,
            userTotalBorrowLimitCents,
            userTotalBorrowBalanceCents,
            userTotalSupplyBalanceCents,
        },
    } = useGetUserMarketInfo({
        accountAddress,
    });


    const {
        suppliedAssets,
        supplyMarketAssets,
        borrowingAssets,
        borrowMarketAssets,
    } =
        useMemo(() => {
            const sortedAssets = assets.reduce(
                (acc, curr) => {
                    if (curr.supplyBalance.isGreaterThan(0)) {
                        acc.suppliedAssets.push(curr);
                    } else {
                        acc.supplyMarketAssets.push(curr);
                    }

                    if (curr.borrowBalance.isGreaterThan(0)) {
                        acc.borrowingAssets.push(curr);
                    } else if (curr.token.address !== TOKENS.xvs.address) {
                        acc.borrowMarketAssets.push(curr);
                    }
                    return acc;
                },
                {
                    suppliedAssets: [] as Asset[],
                    supplyMarketAssets: [] as Asset[],
                    borrowingAssets: [] as Asset[],
                    borrowMarketAssets: [] as Asset[],
                },
            );
            return sortedAssets;
        }, [JSON.stringify(assets)]);

    return (
        <>
            <div>
                <Header />
                {/* <MarketTabl /> */}
                <Markets
                  isXvsEnabled={isXvsEnabled}
                  accountAddress={accountAddress}
                  userTotalBorrowLimitCents={userTotalBorrowLimitCents}
                  suppliedAssets={suppliedAssets}
                  supplyMarketAssets={supplyMarketAssets}
                  borrowingAssets={borrowingAssets}
                  borrowMarketAssets={borrowMarketAssets}
                  vaults={vaults}
                />
            </div>
        </>
    );
};

const Market: React.FC = () => <MarketUi />;

export default Market;
