import { useMutation, MutationObserverOptions } from 'react-query';

import queryClient from 'clients/api/queryClient';
import FunctionKey from 'constants/functionKey';
import { useUmiTokenContract } from 'clients/contracts/hooks';
import setInviter, { ISetInviterInput, setInviterOutput } from './setInviter';

const useSetInviter = (
    options?: MutationObserverOptions<
        setInviterOutput,
        Error,
        Omit<ISetInviterInput, 'UmiTokenContract'>
        >,
) => {
    const UmiTokenContract = useUmiTokenContract();
    return useMutation(
        FunctionKey.SET_INVITER,
        params =>
            setInviter({
                UmiTokenContract,
                ...params,
            }),
        {
            ...options,
            onSuccess: (...onSuccessParams) => {
                queryClient.invalidateQueries(FunctionKey.SET_INVITER);

                if (options?.onSuccess) {
                    options.onSuccess(...onSuccessParams);
                }
            },
        },
    );
};

export default useSetInviter;
