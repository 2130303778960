import { checkForVaiControllerTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core';

import { VaiController, UmiVault } from 'types/contracts';

export interface RepayUmiInput {
  umiControllerContract: UmiVault;
  fromAccountAddress: string;
  amountWei: string;
}

export type IRepayUmiOutput = TransactionReceipt;

const repayUmi = async ({
                          umiControllerContract,
  fromAccountAddress,
  amountWei,
}: RepayUmiInput): Promise<IRepayUmiOutput> => {
  const resp = await umiControllerContract.methods
    .Withdrawn(amountWei)
    .send({ from: fromAccountAddress });
  return checkForVaiControllerTransactionError(resp);
};

export default repayUmi;
