import BigNumber from 'bignumber.js';
import { getContractAddress } from 'utilities';

import { VenusLens, UmiVault } from 'types/contracts';

export interface GetUmiPriceInput {
  VaultContract: UmiVault;
  accountAddress: string;
}

export type GetUmiPriceOutput = {
  getUmiPrice: string;
  getBnbPrice: string;
  getSmcPrice: string;
  getPoolPrice: string;
};

const getUmiPrice = async ({
                           VaultContract,
  accountAddress,
}: GetUmiPriceInput): Promise<GetUmiPriceOutput> => {
  const res = await VaultContract.methods
      .getPrice()
    .call();
  return {
    getUmiPrice: res['0'],
    getBnbPrice: res['1'],
    getSmcPrice: res['2'],
    getPoolPrice: res['3'],
  };
};

export default getUmiPrice;
