import BigNumber from 'bignumber.js';

import { VBep20, VBnbToken, UmiToken, UmiVault } from 'types/contracts';

export interface GetOrganizationInput {
  UmiVaultContract: UmiVault;
  accountAddress: string;
}

export type GetOrganizationOutput = {
  inviter: string;
};

const getOrganization = async ({
                                   UmiVaultContract, accountAddress,
}: GetOrganizationInput): Promise<GetOrganizationOutput> => {
  const res = await UmiVaultContract.methods.organization().call();

  return {
    inviter: res,
  };
};

export default getOrganization;
