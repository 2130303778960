/** @jsxImportSource @emotion/react */
import { Form, Formik, FormikConfig, FormikHelpers, FormikProps } from 'formik';
import React from 'react';

import useIsMounted from 'hooks/useIsMounted';

import getValidationSchema, { FormValues } from './validationSchema';

export * from './validationSchema';

export interface AmountFormProps
  extends Omit<FormikConfig<FormValues>, 'onSubmit' | 'initialValues'> {
  onSubmit: (value: string, umivalue: string) => Promise<unknown>;
  children: (formProps: FormikProps<FormValues>) => React.ReactNode;
  initialAmount?: FormikConfig<FormValues>['initialValues']['amount'];
  maxAmount?: FormikConfig<FormValues>['initialValues']['umiAmount'];
  umiAmount?: string;
  bnbAmount?: string;
  className?: string;
}

export const AmountForm: React.FC<AmountFormProps> = ({
  children,
  onSubmit,
  className,
  initialAmount = '',
  maxAmount = '',
    umiAmount = '',
                                                      bnbAmount = '',
}) => {
  const isMounted = useIsMounted();

  const handleSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    if (bnbAmount) {
      await onSubmit(bnbAmount, umiAmount);
    }

    if (values.amount && isMounted()) {
      resetForm();
    }
  };
  return (
    <Formik
      initialValues={{
        amount: initialAmount,
        umiAmount: initialAmount,
      }}
      onSubmit={handleSubmit}
      // validationSchema={getValidationSchema(maxAmount)}
      isInitialValid={false}
      validateOnMount
      validateOnChange
    >
      {formikProps => <Form className={className}>{children(formikProps)}</Form>}
    </Formik>
  );
};
