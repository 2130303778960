import { MutationObserverOptions, QueryObserverOptions, useMutation, useQuery } from 'react-query';

import getStakePool, { GetStakePoolInput, GetStakePoolOutput } from 'clients/api/queries/getStakePool';
import { useUmiTokenContract, useUmiVaultContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import queryClient from 'clients/api/queryClient';


type Options = QueryObserverOptions<
    GetStakePoolOutput,
    Error,
    GetStakePoolOutput,
    GetStakePoolOutput,
    [FunctionKey.GET_STAKE_POOL, string]
>;

const useGetStakePool = (
    { accountAddress }: Omit<GetStakePoolInput, 'UmiVaultContract'>,
    options?: Options,
) => {
  const UmiVaultContract = useUmiVaultContract();
    return useQuery(
        [FunctionKey.GET_STAKE_POOL, accountAddress],
        () =>
            getStakePool({
                UmiVaultContract,
                accountAddress,
            }),
        options,
    );
};

export default useGetStakePool;
