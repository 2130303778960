import BigNumber from 'bignumber.js';

import { VBep20, VBnbToken, UmiToken, UmiVault } from 'types/contracts';

export interface GetStakePoolInput {
    UmiVaultContract: UmiVault;
  accountAddress: string;

}

export type GetStakePoolOutput = {
    Amount: string;
};

const getStakePool = async ({
                                  UmiVaultContract,
                               accountAddress,
}: GetStakePoolInput): Promise<GetStakePoolOutput> => {
  const res = await UmiVaultContract.methods.stakePool().call();

  return {
      Amount: res,
  };
};

export default getStakePool;
