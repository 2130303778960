import { MutationObserverOptions, QueryObserverOptions, useMutation, useQuery } from 'react-query';

import getInviter, { GetInviterOutput, GetInviterInput } from 'clients/api/queries/getInviter';
import { useUmiTokenContract, useVTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import queryClient from 'clients/api/queryClient';


type Options = QueryObserverOptions<
    GetInviterOutput,
    Error,
    GetInviterOutput,
    GetInviterOutput,
    [FunctionKey.GET_INVITER, string]
>;

const useGetInviter = (
    { accountAddress }: Omit<GetInviterInput, 'UmiTokenContract'>,
    options?: Options,
) => {
  const UmiTokenContract = useUmiTokenContract();
    return useQuery(
        [FunctionKey.GET_INVITER, accountAddress],
        () =>
            getInviter({
                UmiTokenContract,
                accountAddress,
            }),
        options,
    );
};

export default useGetInviter;
